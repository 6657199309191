import { useContext, useMemo, useEffect } from "react"
import {
  QueryStatus,
  useIsFetching,
  useIsMutating,
} from "@tanstack/react-query"
import { ColumnFiltersState, SortingState } from "@tanstack/react-table"

import PartnersTable, { BaseColumn } from "./PartnersTable"
import {
  eligibleTableColumns,
  EligibleTableColumnType,
} from "./PartnersTable/helpers"
import PartnersEligibleLeadsContact from "./PartnersEligibleLeadsContact"
import PartnersEligibleLeadsUnlockDisqualify from "./PartnersEligibleLeadsUnlockDisqualify"
import PartnersAccount from "./PartnersAccount"
import PartnersAccountMap from "./PartnersAccountMap"
import { Spinner } from "../../components/Spinner"
import {
  EligibleLeadColumnType,
  EligibleLeadFilterFunctionsTypes,
  PARTNERS_ACTIONS,
  PartnersContext,
} from "../../context/PartnersContext"
import { ELIGIBLE_LEADS } from "../../shared/constants"
import { usePartnersTableResultsText } from "../../hooks/usePartnersTableReusltsText"
import {
  EligibleLeadsTypes,
  EligibleLeadTypes,
  PartnersLandownerTypes,
  ReachoutsCounterTypes,
} from "../../types/partners"

interface PartnersEligibleLeadsTypes {
  eligibleLeads: EligibleLeadsTypes | undefined
  userFirstName: string | null | undefined
  account: PartnersLandownerTypes | undefined
  accountStatus: QueryStatus
  reachoutsCounter: ReachoutsCounterTypes | undefined
}

type ColumnType = BaseColumn & EligibleTableColumnType

const PartnersEligibleLeads = ({
  eligibleLeads,
  userFirstName,
  account,
  accountStatus,
  reachoutsCounter,
}: PartnersEligibleLeadsTypes) => {
  const {
    state: {
      activeTab,
      eligibleActiveRowUnlocked,
      eligibleLeadDataLoaded,
      eligibleLeadSorting,
      eligibleLeadColumnFilters,
      eligibleLeadFilters,
      eligibleLeadFilterFunctions,
    },
    dispatch,
  } = useContext(PartnersContext)

  const isTablePageLoading = useIsMutating({
    mutationKey: ["partners", ELIGIBLE_LEADS],
  })

  const isDataFetching = useIsFetching({
    queryKey: ["partners", ELIGIBLE_LEADS],
  })

  const columns = useMemo(
    () => eligibleTableColumns(eligibleLeadFilterFunctions),
    [eligibleLeadFilterFunctions]
  )

  const resultsText: string = usePartnersTableResultsText(
    eligibleLeads?.count ?? 0,
    eligibleLeads?.previous ?? null,
    eligibleLeads?.next ?? null,
    eligibleLeads?.results ?? []
  )

  const handleSelectRow = (row: EligibleLeadTypes | null) => {
    dispatch({
      type: PARTNERS_ACTIONS.setActiveRow,
      payload: row,
    })
    dispatch({
      type: PARTNERS_ACTIONS.setViewport,
      payload: null,
    })
    dispatch({
      type: PARTNERS_ACTIONS.setEligibleActiveRowUnlocked,
      payload: row !== null && row?.status !== "hidden",
    })
  }

  useEffect(() => {
    if (eligibleLeads?.results && activeTab === ELIGIBLE_LEADS) {
      const firstResult = eligibleLeads.results[0]

      dispatch({
        type: PARTNERS_ACTIONS.setActiveRow,
        payload: firstResult,
      })
      dispatch({
        type: PARTNERS_ACTIONS.setEligibleActiveRowUnlocked,
        payload: firstResult?.status !== "hidden",
      })
    }
  }, [eligibleLeads, dispatch, activeTab])

  if (activeTab !== ELIGIBLE_LEADS) {
    return null
  }

  if (!eligibleLeadDataLoaded) {
    return (
      <div className="bg-gray-0 h-screen flex items-center justify-center">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="grid grid-cols-1 md:gap-6 md:grid-cols-3">
      <div className="col-span-2">
        <div className="w-full card card-content self-start mb-6">
          <p className="text-dusk-500">
            <span className="italic font-semibold">NEW</span> View landowners
            who have been remotely assessed as eligible for your program but
            haven't requested a call. Unlock and message up to{" "}
            {reachoutsCounter?.max_reachouts} leads a month to see the
            landowner's property details.
          </p>
        </div>

        <PartnersTable<EligibleLeadTypes[], ColumnType[]>
          data={eligibleLeads?.results}
          onRowClick={handleSelectRow}
          filterFunctions={eligibleLeadFilterFunctions}
          setFilterFunctions={(val: EligibleLeadFilterFunctionsTypes) => {
            dispatch({
              type: PARTNERS_ACTIONS.setEligibleLeadFilterFunctions,
              payload: val,
            })
          }}
          columnFilters={eligibleLeadColumnFilters}
          setColumnFilters={(val: ColumnFiltersState) => {
            dispatch({
              type: PARTNERS_ACTIONS.setEligibleLeadColumnFilters,
              payload: val,
            })
          }}
          filters={eligibleLeadFilters}
          setFilters={(val: EligibleLeadColumnType[]) => {
            dispatch({
              type: PARTNERS_ACTIONS.setEligibleLeadFilters,
              payload: val,
            })
          }}
          columns={columns as ColumnType[]}
          sorting={eligibleLeadSorting}
          setSorting={(val: SortingState) => {
            dispatch({
              type: PARTNERS_ACTIONS.setEligibleLeadSorting,
              payload: val,
            })
          }}
          resultsText={resultsText}
          next={eligibleLeads?.next}
          previous={eligibleLeads?.previous}
          paginationEnabled={true}
          isTableLoading={isTablePageLoading === 1 || isDataFetching === 1}
        />

        {eligibleActiveRowUnlocked ? (
          <PartnersAccountMap
            bounds={account?.bounds as string}
            accountStatus={accountStatus}
          />
        ) : null}
      </div>
      <div className="flex flex-col gap-6">
        <PartnersEligibleLeadsUnlockDisqualify
          action={eligibleActiveRowUnlocked ? "disqualify" : "unlock"}
          handleSelectRow={handleSelectRow}
          reachoutsCounter={reachoutsCounter}
        />

        {eligibleActiveRowUnlocked ? (
          <>
            <PartnersEligibleLeadsContact
              userFirstName={userFirstName}
              reachoutsCounter={reachoutsCounter}
            />

            <PartnersAccount
              unlocked={false}
              account={account}
              accountStatus={accountStatus}
            />
          </>
        ) : null}
      </div>
    </div>
  )
}

export default PartnersEligibleLeads
