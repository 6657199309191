import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DataType } from "../PartnersTable"

import { Column } from "@tanstack/react-table"
import {
  EligibleLeadColumnType,
  QualifiedLeadColumnType,
} from "@/context/PartnersContext"

interface TableFiltersAddTypes<TData extends DataType[]> {
  allColumns: Column<TData[number], unknown>[]
  filters: QualifiedLeadColumnType[] | EligibleLeadColumnType[]
  setFilters: (
    val: QualifiedLeadColumnType[] & EligibleLeadColumnType[]
  ) => void
}

const TableFiltersAdd = <TData extends DataType[]>({
  allColumns,
  filters,
  setFilters,
}: TableFiltersAddTypes<TData>) => (
  <div className="w-[830px] max-w-full text-left p-3">
    <button
      type="button"
      className="hover:opacity-80 disabled:hover:opacity-100 disabled:text-gray2 transition-opacity duration-200"
      onClick={() => {
        const availableColumns = allColumns.filter(
          (column) =>
            !filters.includes(column.id as EligibleLeadColumnType) &&
            column.id !== "select"
        )
        const newFilter = availableColumns[0].columnDef.id
        setFilters([...filters, newFilter] as QualifiedLeadColumnType[] &
          EligibleLeadColumnType[])
      }}
      disabled={filters.length === allColumns.length}
    >
      <FontAwesomeIcon icon={faPlus} /> Add
    </button>
  </div>
)

export default TableFiltersAdd
