import { useContext, useState, useMemo } from "react"
import {
  ColumnFiltersState,
  RowSelectionState,
  SortingState,
} from "@tanstack/react-table"
import { QueryStatus } from "@tanstack/react-query"
import { DialogStateReturn } from "reakit/Dialog"

import NothingFound from "../../components/NothingFound"
import PartnersAccount from "./PartnersAccount"
import PartnersAccountMap from "./PartnersAccountMap"
import PartnersTable, { BaseColumn } from "./PartnersTable"
import {
  qualifiedTableColumns,
  QualifiedTableColumnType,
} from "./PartnersTable/helpers"
import {
  PARTNERS_ACTIONS,
  PartnersContext,
  QualifiedLeadColumnType,
  QualifiedLeadFilterFunctionsTypes,
} from "../../context/PartnersContext"
import { QUALIFIED_LEADS } from "../../shared/constants"
import { PartnersLandownerTypes, RowsType, RowType } from "@/types/partners"
import { Profile } from "@/types"

interface PartnersQualifiedLeadsTypes {
  partnerDashboardData: RowsType | undefined
  profile: Profile | undefined
  inviteDealOwnerDialog: DialogStateReturn
  editDealOwnerDialog: DialogStateReturn
  account: PartnersLandownerTypes | undefined
  accountStatus: QueryStatus
}

type ColumnType = BaseColumn & QualifiedTableColumnType

const PartnersQualifiedLeads = ({
  partnerDashboardData,
  profile,
  inviteDealOwnerDialog,
  editDealOwnerDialog,
  account,
  accountStatus,
}: PartnersQualifiedLeadsTypes) => {
  const {
    state: {
      activeTab,
      qualifiedLeadSorting,
      qualifiedLeadColumnFilters,
      qualifiedLeadFilters,
      qualifiedLeadFilterFunctions,
    },
    dispatch,
  } = useContext(PartnersContext)

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const columns = useMemo(
    () =>
      qualifiedTableColumns(
        qualifiedLeadFilterFunctions,
        inviteDealOwnerDialog,
        editDealOwnerDialog,
        dispatch,
        profile
      ),
    [
      qualifiedLeadFilterFunctions,
      inviteDealOwnerDialog,
      editDealOwnerDialog,
      dispatch,
      profile,
    ]
  )

  if (activeTab === QUALIFIED_LEADS && partnerDashboardData?.length === 0) {
    return (
      <NothingFound
        title="No qualified leads found"
        text="There are no qualified leads associated with this partner account yet."
      />
    )
  }

  if (activeTab !== QUALIFIED_LEADS) {
    return null
  }

  return (
    <div className="grid grid-cols-1 md:gap-6 md:grid-cols-3">
      <div className="col-span-2 partners-table qualified-leads">
        <PartnersTable<RowsType, ColumnType[]>
          data={partnerDashboardData}
          onRowClick={(row: RowType) => {
            dispatch({
              type: PARTNERS_ACTIONS.setActiveRow,
              payload: row,
            }),
              dispatch({
                type: PARTNERS_ACTIONS.setViewport,
                payload: null,
              })
          }}
          filterFunctions={qualifiedLeadFilterFunctions}
          setFilterFunctions={(val: QualifiedLeadFilterFunctionsTypes) => {
            dispatch({
              type: PARTNERS_ACTIONS.setQualifiedLeadFilterFunctions,
              payload: val,
            })
          }}
          columnFilters={qualifiedLeadColumnFilters}
          setColumnFilters={(val: ColumnFiltersState) => {
            dispatch({
              type: PARTNERS_ACTIONS.setQualifiedLeadColumnFilters,
              payload: val,
            })
          }}
          filters={qualifiedLeadFilters}
          setFilters={(val: QualifiedLeadColumnType[]) =>
            dispatch({
              type: PARTNERS_ACTIONS.setQualifiedLeadFilters,
              payload: val,
            })
          }
          columns={columns as ColumnType[]}
          sorting={qualifiedLeadSorting}
          setSorting={(val: SortingState) =>
            dispatch({
              type: PARTNERS_ACTIONS.setQualifiedLeadSorting,
              payload: val,
            })
          }
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
        />

        <PartnersAccountMap
          bounds={account?.bounds as string}
          accountStatus={accountStatus}
        />
      </div>

      <PartnersAccount
        account={account}
        accountStatus={accountStatus}
        selectedRows={rowSelection}
        setRowSelection={setRowSelection}
      />
    </div>
  )
}

export default PartnersQualifiedLeads
