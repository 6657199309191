import { useContext, useEffect, useMemo, useRef, useState } from "react"
import {
  ColumnFiltersState,
  ColumnOrderState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  OnChangeFn,
  RowSelectionState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table"
import { useVirtualizer } from "@tanstack/react-virtual"
import cx from "classnames"
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers"
import {
  arrayMove,
  SortableContext,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable"

import DraggableTableHeader from "./PartnersTable/DraggableTableHeader"
import DragAlongCell from "./PartnersTable/DragAlongCell"
import TableFilters from "./PartnersTable/TableFilters"
import ExportCSV from "./PartnersTable/ExportCSV"
import TableBulkActions from "./PartnersTable/TableBulkActions"
import TablePagination from "./PartnersTable/TablePagination"
import { filterFns } from "./PartnersTable/helpers"
import { QUALIFIED_LEADS } from "../../shared/constants"
import {
  EligibleLeadColumnType,
  EligibleLeadFilterFunctionsTypes,
  PartnersContext,
  QualifiedLeadColumnType,
  QualifiedLeadFilterFunctionsTypes,
} from "../../context/PartnersContext"
import { RowType } from "../../types/partners"

export interface DataType {
  id: string | number
  unlocked_at: string | null | undefined
}

export interface BaseColumn {
  id: string
  hidden?: boolean
}

interface PartnersTableTypes<
  TData extends DataType[],
  TColumns extends BaseColumn[],
> {
  data: TData | undefined
  columns: TColumns
  onRowClick: (row: TData[number]) => void
  filterFunctions:
    | QualifiedLeadFilterFunctionsTypes
    | EligibleLeadFilterFunctionsTypes
  setFilterFunctions: (
    val: QualifiedLeadFilterFunctionsTypes & EligibleLeadFilterFunctionsTypes
  ) => void
  sorting: SortingState
  setSorting: (val: SortingState) => void
  columnFilters: ColumnFiltersState
  setColumnFilters: (val: ColumnFiltersState) => void
  filters: QualifiedLeadColumnType[] | EligibleLeadColumnType[]
  setFilters: (
    val: QualifiedLeadColumnType[] & EligibleLeadColumnType[]
  ) => void
  rowSelection?: RowSelectionState
  setRowSelection?: OnChangeFn<RowSelectionState>
  resultsText?: string
  next?: string | null
  previous?: string | null
  paginationEnabled?: boolean
  isTableLoading?: boolean
}

const PartnersTable = <
  TData extends DataType[],
  TColumns extends BaseColumn[],
>({
  data,
  columns,
  onRowClick,
  filterFunctions,
  setFilterFunctions,
  sorting,
  setSorting,
  columnFilters,
  setColumnFilters,
  filters,
  setFilters,
  rowSelection,
  setRowSelection,
  resultsText,
  next,
  previous,
  paginationEnabled = false,
  isTableLoading = false,
}: PartnersTableTypes<TData, TColumns>) => {
  const {
    state: { activeRow, activeTab },
  } = useContext(PartnersContext)

  const containerRef = useRef<HTMLDivElement | null>(null)
  const parentRef = useRef<HTMLDivElement | null>(null)
  const tableRef = useRef<HTMLTableElement | null>(null)

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(() =>
    columns.map((c) => c.id || "")
  )

  const table = useReactTable({
    data: data as TData,
    columns: columns.filter((column) => !column.hidden),
    getRowId: (row: TData[number]) => row.id.toString(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: (updater) => {
      const newSortingValue =
        updater instanceof Function ? updater(sorting) : updater
      setSorting(newSortingValue)
    },
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: (updater) => {
      const newFiltersValue =
        updater instanceof Function ? updater(columnFilters) : updater
      setColumnFilters(newFiltersValue)
    },
    manualFiltering: activeTab === "eligible_leads",
    onColumnOrderChange: setColumnOrder,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnOrder,
      rowSelection,
    },
    filterFns,
  })

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event
    if (active && over && active.id !== over.id) {
      setColumnOrder((columnOrder) => {
        const oldIndex = columnOrder.indexOf(active.id as string)
        const newIndex = columnOrder.indexOf(over.id as string)
        return arrayMove(columnOrder, oldIndex, newIndex)
      })
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  )

  const filteredData = table.getRowModel().flatRows.map((row) => row.original)
  const allColumns = table.getAllColumns()
  const filterableColumns = allColumns.filter(
    (c) => c.columnDef.filterFn !== null
  )
  const firstFilter =
    filterableColumns[activeTab === "qualified_leads" ? 1 : 0].columnDef.id

  const totalRowsNum = Object.keys(table.getRowModel().rowsById).length

  const { rows } = table.getRowModel()

  useEffect(() => {
    if (filters?.length === 0) {
      setFilters([
        firstFilter as EligibleLeadColumnType & QualifiedLeadColumnType,
      ])
    }

    if (rows.length > 0) {
      onRowClick(rows[0].original)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // scroll to top when rows change
  useEffect(() => {
    if (parentRef.current) {
      parentRef.current.scrollTop = 0
    }
  }, [rows])

  const csvColumns = filterableColumns.reduce<{
    ids: (string | undefined)[]
    headers: any
  }>(
    (acc, { columnDef }) => ({
      ids: [...acc.ids, columnDef.id],
      headers: [...acc.headers, columnDef.header],
    }),
    {
      ids: [],
      headers: [],
    }
  )

  const csvData = useMemo(() => {
    return [
      csvColumns.headers,
      ...filteredData.map((item) => {
        return csvColumns.ids.map((id) => {
          if (id === "deal_owner") {
            return (item[id as keyof typeof item] as RowType)?.email || ""
          }
          return item[id as keyof typeof item]
        })
      }),
    ]
  }, [csvColumns, filteredData])

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 63,
    overscan: 20,
  })

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <div
        ref={containerRef}
        className="card card-content relative bg-cloud-50 mb-6 p-0"
      >
        {isTableLoading ? (
          // DEV: Display a semi-transparent overlay over the table when table data is fetching
          <div className="absolute top-[72px] left-0 right-0 h-[504px] flex justify-center items-center bg-white bg-opacity-20 z-50" />
        ) : null}

        <div className="flex justify-between items-center border-b-1 border-b-dusk-50 text-dusk text-base text-center font-semibold leading-130 tracking-0.32 px-3 py-3 lg:px-5 lg:py-4 -mt-[1px]">
          <div className="flex gap-2 font-normal leading-130 tracking-0.32">
            <p>
              {resultsText !== undefined
                ? resultsText
                : `${totalRowsNum} results`}
            </p>

            {isTableLoading ? (
              // DEV: Display a spinner icon when table data is fetching
              <i className="fas fa-lg fa-circle-notch fa-spin text-xs"></i>
            ) : null}
          </div>

          <div className="flex gap-6">
            <ExportCSV csvData={csvData} />

            {activeTab === QUALIFIED_LEADS && (
              <TableBulkActions
                selectedRows={rowSelection}
                onUpdateSuccess={() => setRowSelection && setRowSelection({})}
              />
            )}

            <TableFilters
              allColumns={filterableColumns}
              filters={filters}
              setFilters={setFilters}
              filterFunctions={filterFunctions}
              setFilterFunctions={setFilterFunctions}
              resetColumnFilters={table.resetColumnFilters}
              tableState={table.options.state}
            />
          </div>
        </div>

        <div className="rounded-t-lg overflow-hidden">
          <div className="relative overflow-auto h-[504px]" ref={parentRef}>
            <table
              ref={tableRef}
              className="grid w-full text-base text-left leading-130 tracking-0.14 text-charcoal-500 border-separate border-spacing-0"
            >
              <thead className="whitespace-nowrap sticky top-0 bg-white z-[2]">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className="flex w-full">
                    <SortableContext
                      items={columnOrder}
                      strategy={horizontalListSortingStrategy}
                    >
                      {headerGroup.headers.map((header, i, arr) => (
                        <DraggableTableHeader
                          key={header.id}
                          header={header}
                          i={i}
                          arr={arr}
                        />
                      ))}
                    </SortableContext>
                  </tr>
                ))}
              </thead>

              <tbody
                className="grid relative"
                style={{
                  height: `${virtualizer.getTotalSize()}px`,
                }}
              >
                {virtualizer.getVirtualItems().map((virtualRow) => {
                  const row = rows[virtualRow.index]

                  return (
                    <tr
                      tabIndex={0}
                      key={row.id}
                      id={row.id}
                      className={cx(
                        "hover:bg-grass-50 focus:ring-0 cursor-pointer flex absolute w-full",
                        {
                          "bg-cloud-300":
                            typeof row.original.unlocked_at === "string" &&
                            activeTab === QUALIFIED_LEADS,
                        },
                        { "!bg-grass-50": row.original.id === activeRow?.id }
                      )}
                      style={{
                        height: `${virtualRow.size}px`,
                        transform: `translateY(${virtualRow.start}px)`,
                      }}
                      onClick={() => onRowClick(row.original)}
                    >
                      {row.getVisibleCells().map((cell, i, arr) => (
                        <SortableContext
                          key={cell.id}
                          items={columnOrder}
                          strategy={horizontalListSortingStrategy}
                        >
                          <DragAlongCell
                            key={cell.id}
                            cell={cell}
                            i={i}
                            arr={arr}
                          />
                        </SortableContext>
                      ))}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>

        <TablePagination
          previous={previous}
          next={next}
          enabled={paginationEnabled}
          isTableLoading={isTableLoading}
        />
      </div>
    </DndContext>
  )
}

export default PartnersTable
